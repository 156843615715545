import { useEffect } from "react";
import Router from "next/router";
import { _fetch } from "../utils/helper";
import { useAtom } from "jotai";
import { userAtom } from "../stores/global";

//import useSWR from "swr";

const useUser = ({ redirectTo, redirectIfFound } = {}) => {
  const [user, setUser] = useAtom(userAtom);
  let data;
  let finished;
  let hasUser;

  const refreshUser = () => {
    _fetch("/api/auth/me", { method: "GET" })
      .then((data) => {
        //console.log("From auth hook", data);

        if (data.loggedIn) {
          setUser(data);
          hasUser = true;
        } else {
          setUser({ loggedIn: false });
          hasUser = false;
        }

        finished = true;
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    _fetch("/api/auth/me", { method: "GET" })
      .then((data) => {
        console.log("From auth hook", data);

        if (data.loggedIn) {
          setUser(data);
          hasUser = true;
        } else {
          setUser({ loggedIn: false });
          hasUser = false;
        }
        finished = true;
      })
      .catch((e) => console.error(e));
  }, [user.loggedIn]);

  return { user, refreshUser };
};
export { useUser };
